<offconon-shared-ui-virtualscroll-dropdown
  data-testid="category-dropdown__search"
  [showClear]="false"
  [optionTemplate]="optionTemplate"
  [selectedOptionTemplate]="selectedOptionTemplate"
  [selectOptions]="(locationList$ | async) || []"
  [selectedOption]="$any(commonDropdownAndMultiselectService.selectedOptions$) | async"
  (selectedOptionOutput)="select($event)"
  (paginationAndSearchOutput)="
    commonDropdownAndMultiselectService.onDataChange($event, dropdownData$, {
      keyword_translation: $event.searchString,
      category_type
    })
  " />

<ng-template #optionTemplate let-item="item">
  <div class="flex items-center" data-testid="category-dropdown__option">
    @if (!item.cover_path && item.name) {
      <offconon-monogram
        data-testid="category-dropdown__monogram"
        [avatarClass]="'ml-auto mt-2'"
        [options]="{ size: '3.2rem' }"
        [name]="item.name" />
    }

    @if (item.cover_path) {
      <p-avatar
        data-testid="category-dropdown__avatar"
        styleClass="mt-2"
        size="large"
        shape="circle"
        image="{{ item.cover_path }}" />
    }

    <div class="ml-1" data-testid="category-dropdown__item-content">
      <span
        class="block mb-1 mt-1 text-sm font-medium white-space-nowrap"
        data-testid="category-dropdown__item-name">
        {{ item.name }}
      </span>

      <div
        class="m-0 text-xs text-blue-500"
        data-testid="category-dropdown__item-path"
        [innerHTML]="item.category_path | joinCategoryPath: 'pi pi-chevron-right' : ' '"></div>
    </div>
  </div>
</ng-template>

<ng-template #selectedOptionTemplate let-item="item">
  @if (item) {
    <div class="flex items-center" data-testid="category-dropdown__selected">
      @if (!item.cover_path && item.name) {
        <offconon-monogram
          data-testid="category-dropdown__selected-monogram"
          [avatarClass]="'ml-auto mt-2'"
          [options]="{ size: '3.2rem' }"
          [name]="item.name" />
      }

      @if (item.cover_path) {
        <p-avatar
          data-testid="category-dropdown__selected-avatar"
          styleClass="mt-2"
          size="large"
          shape="circle"
          image="{{ item.cover_path }}" />
      }

      <div class="ml-1" data-testid="category-dropdown__selected-content">
        <span
          class="block mb-1 mt-1 text-sm font-medium white-space-nowrap"
          data-testid="category-dropdown__selected-name">
          {{ item.name }}
        </span>

        <div
          class="m-0 text-xs text-blue-500"
          data-testid="category-dropdown__selected-path"
          [innerHTML]="item.category_path | joinCategoryPath: 'pi pi-chevron-right' : ' '"></div>
      </div>
    </div>
  }
</ng-template>

@if (categoryFormControl.value && create_new) {
  <small
    class="block mt-1 leading-6 cursor-pointer"
    data-testid="category-dropdown__create-new"
    (click)="createNew()">
    {{ block_description }}
    {{ 'You can create a new one here.' | translate }}
    <span class="text-blue-500">{{ 'Click here' | translate }}. </span>

    <span class="text-red-500">
      {{ 'Select the main category under which you want to create the new category.' | translate }}
    </span>
  </small>
}

<p-drawer
  #creatorSideBarRef
  data-testid="category-dropdown__creator-sidebar"
  [fullScreen]="true"
  [appendTo]="'body'"
  [modal]="false"
  [closeOnEscape]="true"
  [showCloseIcon]="false"
  [(visible)]="creatorSideBar">
  <div
    class="flex flex-col lg:flex-row items-center justify-between mb-3"
    data-testid="category-dropdown__creator-header">
    <div class="text-center lg:text-left lg:pr-8">
      <div
        class="text-primary font-light mt-3 mb-1 text-xl"
        data-testid="category-dropdown__creator-title">
        {{ 'Create new category' | translate }}
      </div>
      <div class="text-sm mb-3 mt-2" data-testid="category-dropdown__creator-description">
        {{
          'Select the main category under which you want to create the new category.' | translate
        }}
      </div>
    </div>
    <div class="mt-2 lg:mt-0 flex flex-wrap lg:flex-nowrap">
      <button
        pButton
        icon="pi pi-times"
        iconPos="left"
        class="px-5 py-3 p-button-outlined p-button-rounded p-button-danger font-bold w-auto white-space-nowrap"
        style="white-space: nowrap"
        data-testid="category-dropdown__creator-close"
        label="{{ 'Close' | translate }}"
        (click)="getClosePanel()"></button>
    </div>
  </div>
  <p-divider class="w-full" />

  <div class="p-2">
    <div class="pt-3">
      @if (creatorSideBar && categoryFormControl.value && create_new) {
        <offconon-category-template-create-common-form
          data-testid="category-dropdown__creator-form"
          [parameters]="parameters"
          [parent_id]="categoryFormControl.value"
          (formSavedEvent)="savedForm($event)" />
      }
    </div>
  </div>
</p-drawer>
