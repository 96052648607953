<form action="" class="grip w-full max-w-full pb-5" [formGroup]="form" (submit)="submit()">
  <div class="flex flex-col">
    <div class="flex flex-col gap-2 mb-4">
      <label class="font-medium">{{ 'Language' | translate }}</label>
      <offconon-langauge-dropdown-virtual-scroll-common
        [selectedId]="actual_language_id"
        [parameters]="language_parameters"
        [languageFormControl]="languageFormControl" />
      <small class="block mt-1 leading-6 text-xs">{{
        'Our system supports multilingual display. Select the language you want to add or edit.'
          | translate
      }}</small>
    </div>

    <div class="flex flex-col gap-2 mb-4">
      <span class="p-float-label">
        <input formControlName="name" id="name" type="text" pInputText class="w-full" />
        <label for="name"> {{ 'Name' | translate }}</label>
      </span>
      <small class="block mt-1 leading-6 text-xs"
        >{{ 'Summarize your service in a few words.' | translate }}
        {{ 'Maximum characters:' | translate }} 85
      </small>
    </div>

    <div class="flex flex-col gap-2 mb-4">
      <label for="description" class="font-medium">
        {{ 'Description' | translate }} ( {{ 'Optional' | translate }} )</label
      >
      <textarea
        id="description"
        type="text"
        pTextarea
        rows="3"
        name="description"
        [autoResize]="false"
        [formControlName]="'description'"></textarea>
      <small class="block mt-1 leading-6 text-xs"
        >{{ 'Describe in a few words what this category is.' | translate }}
        {{ 'Maximum characters:' | translate }} 500
      </small>
    </div>

    <div class="flex flex-col gap-2 mb-4">
      <label class=" "> {{ 'Order among the categories' | translate }}</label>
      <p-inputNumber
        formControlName="order_id"
        buttonLayout="horizontal"
        inputId="horizontal"
        spinnerMode="horizontal"
        decrementButtonClass="p-button-text p-button-danger py-1 px-1"
        incrementButtonClass="p-button-text p-button-success py-1 px-1"
        incrementButtonIcon="pi pi-plus"
        decrementButtonIcon="pi pi-minus"
        [inputStyleClass]="'w-full text-center py-3 px-1 border-transparent text-primary font-bold'"
        [style]="{ width: '100%' }"
        [styleClass]="'border-none '"
        [showButtons]="true"
        [step]="1"
        [min]="1"
        [max]="9000000000000" />
      <small class="block mt-1 leading-6 text-xs">{{
        '(Optional) If you want, you can organize the rules in the order in which they appear.'
          | translate
      }}</small>
    </div>
  </div>
  <p-button
    icon="pi pi-save"
    type="submit"
    [loading]="loading"
    [disabled]="form.invalid"
    [rounded]="true"
    [styleClass]="'w-full'"
    label="{{ 'Save' | translate }}" />
</form>
