import { Component, DestroyRef, EventEmitter, inject, Input, OnInit, Output } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormsModule,
  ReactiveFormsModule,
  FormControl,
} from '@angular/forms';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { MessageService } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputTextModule } from 'primeng/inputtext';
import { Textarea } from 'primeng/textarea';

import { CommonCategoryTemplate } from '@offconon/core-api';
import { KeyWord } from '@offconon/core-api';
import { SearchService } from '@offconon/core-api';
import { LangaugeDropdownVirtualScrollCommonComponent } from '@offconon/shared/ui/langauge-dropdown-virtual-scroll-common';
import { SiteLanguageService } from '@offconon/shared/utils/services';
import { KeywordSaveEditService } from '@offconon/shared/utils/services';

@Component({
  selector: 'offconon-category-template-create-common-form',
  templateUrl: './category-template-create-common-form.component.html',
  imports: [
    FormsModule,
    ReactiveFormsModule,
    InputTextModule,
    Textarea,
    InputNumberModule,
    ButtonModule,
    TranslateModule,
    LangaugeDropdownVirtualScrollCommonComponent,
  ],
})
export class CategoryTemplateCreateCommonFormComponent implements OnInit {
  private fb = inject(FormBuilder);
  private translateService = inject(TranslateService);
  private searchService = inject(SearchService);
  private siteLanguageService = inject(SiteLanguageService);
  private keywordSaveEditService = inject(KeywordSaveEditService);
  private messageService = inject(MessageService);

  @Input() parameters: any;
  @Input() parent_id: any;

  @Output() formSavedEvent = new EventEmitter<any>();

  private destroyRef = inject(DestroyRef);
  actual_language_id = 71;
  language_parameters: any = {};

  loading = false;
  form: FormGroup;

  keyword_id: any;

  keyword_data: KeyWord;

  ngOnInit() {
    this.actual_language_id = this.siteLanguageService.actualSiteLanguageId();
    this.language_parameters = {
      translation_supported: true,
    };

    this.form = this.fb.group({
      name: ['', [Validators.maxLength(85), Validators.minLength(2), Validators.required]],
      active: [true],
      description: ['', [Validators.maxLength(500), Validators.minLength(2)]],
      parent_id: [this.parent_id],
      order_id: [1],
      language_id: [this.actual_language_id, Validators.required],
      keyword_id: [],
      business_id: [this.parameters?.business_id],
    });
  }

  get languageFormControl() {
    return this.form.get('language_id') as FormControl;
  }

  submit() {
    this.loading = true;
    let payload: any = {};

    let keyword_payload: any = {};

    payload = this.form.value;

    keyword_payload = {
      language_id: payload.language_id,
      name: payload.name,
      description: payload.description,
      business_id: payload.business_id,
      user_id: null,
      type: ['category_template'],
    };

    this.keywordSaveEditService.postKeyword(keyword_payload).subscribe({
      next: (data: KeyWord) => {
        this.keyword_data = data;

        if (this.keyword_data) {
          this.keyword_id = this.keyword_data?.id;
        } else {
        }

        if (this.keyword_id) {
          this.searchService
            .searchCategoryTemplateCreate({
              id: payload.id,
              active: payload.active,
              parent_id: payload.parent_id,
              order_id: payload.order_id,
              business_id: payload.business_id,
              keyword_id: this.keyword_id,
              language_id: payload.language_id,
              category_type: this.parameters?.category_type,
            })
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe({
              next: (result: CommonCategoryTemplate | any) => {
                this.formSavedEvent.emit(result);
              },
              error: () => {
                this.loading = false;
              },
              complete: () => {
                this.loading = false;
              },
            });
        } else {
        }
      },
    });
  }
}
