import { AsyncPipe } from '@angular/common';
import {
  Component,
  inject,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { AvatarModule } from 'primeng/avatar';
import { ButtonModule } from 'primeng/button';
import { DividerModule } from 'primeng/divider';
import { Drawer, DrawerModule } from 'primeng/drawer';
import { BehaviorSubject, Observable } from 'rxjs';

import { CategoryTemplateRecursive } from '@offconon/core-api';
import { SearchService } from '@offconon/core-api';
import { CategoryTemplateCreateCommonFormComponent } from '@offconon/shared/ui/category-template-create-common-form';
import { MonogramComponent } from '@offconon/shared/ui/monogram';
import { SharedUiVirtualscrollDropdownComponent } from '@offconon/shared/ui/shared-ui-virtualscroll-dropdown';
import { CommonSelectOption } from '@offconon/shared/ui/shared-ui-virtualscroll-multiselect';
import { CommonDropdownAndMultiselectService, DropdownData } from '@offconon/shared/utils/services';

import { JoinCategoryPathPipe } from '../../pipes/join-category-path.pipe';

interface MappedSystemData {
  id: number;
  parent_id?: number | undefined;
  name: string;
  category_path: string[];
  cover_path: string | undefined;
  children: MappedSystemData[];
}

@Component({
  selector: 'offconon-category-path-dropdown-search-common',
  templateUrl: './category-path-dropdown-search-common.component.html',
  imports: [
    SharedUiVirtualscrollDropdownComponent,
    MonogramComponent,
    AvatarModule,
    ButtonModule,
    CategoryTemplateCreateCommonFormComponent,
    AsyncPipe,
    JoinCategoryPathPipe,
    TranslateModule,
    DrawerModule,
    DividerModule,
  ],
  providers: [CommonDropdownAndMultiselectService],
})
export class CategoryPathDropdownSearchCommonComponent implements OnInit, OnChanges {
  @Input() parameters: any;
  @Input() business_id: any;
  @Input() selectFirst = true;
  @Input() description: any;
  @Input() category_type: any;
  @Input() categoryFormControl: FormControl = new FormControl();

  @Input() create_new = true;

  @ViewChild('creatorSideBarRef') creatorSideBarRef!: Drawer;

  public commonDropdownAndMultiselectService = inject(CommonDropdownAndMultiselectService);
  private searchService = inject(SearchService);
  private translateService = inject(TranslateService);

  creatorSideBar = false;

  block_description: any;

  locationList$: Observable<CommonSelectOption[]>;
  dropdownData$ = new BehaviorSubject<DropdownData<{}>>({ page: 1, pageSize: 50, dataList: [] });

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['category_type']) {
      this.dropdownData$.next({
        page: 1,
        pageSize: 50,
        searchJson: {
          category_type: this.category_type,
        },
        selectedId: undefined,
        dataList: [],
      });
    }
  }

  ngOnInit(): void {
    switch (this.description) {
      case 'product_create_form':
        this.block_description = this.translateService.instant(
          'Identify your plant from our system database to automatically reach customers in our Plants and Garden app.',
        );
        break;
    }

    this.initializeComponent();
    this.commonDropdownAndMultiselectService.selectedOptions$.subscribe({
      next: (option: any) => {
        if (option) {
          this.categoryFormControl.patchValue(option.id);
        }
      },
    });
  }
  private initializeComponent() {
    this.locationList$ = this.commonDropdownAndMultiselectService.fetchData<SearchService, {}>({
      service: this.searchService,
      action: 'searchCategoryTemplatePathList',

      dropdownData: this.dropdownData$,
      mapData: (data: CategoryTemplateRecursive[]) => {
        const result: MappedSystemData[] = [];

        const processItem = (item: any, path: string[] = []) => {
          const newPath = [...path, item.keyword_translation || item.name];

          result.push({
            id: item.id,
            parent_id: item.parent_id,
            name: item.keyword_translation || item.name,
            category_path: newPath,
            cover_path: item?.cover_path,
            children: item.children,
          });

          if (item.children?.length > 0) {
            for (const child of item.children) {
              processItem(child, newPath);
            }
          }
        };

        data.forEach((item: any) => processItem(item));
        return result;
      },
      selectFirst: this.selectFirst,
    });

    this.dropdownData$.next({
      page: 1,
      pageSize: 50,
      selectedId: this.categoryFormControl?.value,
      searchJson: { category_type: this.category_type },
      dataList: [],
    });
  }
  select(selectedCategory: CommonSelectOption) {
    this.categoryFormControl.patchValue(selectedCategory?.id);
  }

  getClosePanel() {
    this.creatorSideBar = false;
  }

  createNew() {
    this.creatorSideBar = true;
  }

  savedForm(data: any) {
    if (data) {
      this.dropdownData$.next({
        page: 1,
        pageSize: 50,
        searchJson: {
          category_type: this.category_type,
        },
        selectedId: data.id,
        dataList: [],
      });
    }
    this.getClosePanel();
  }
}
